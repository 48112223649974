import './App.css';
import * as React from 'react';
import * as data from './projects.json';
import {useState} from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import jQuery from "jquery";

window.$ = window.jQuery = jQuery;
const _ = require('underscore');
const RowClass = "ListRow";
const TitleClass = "ListTitle";
const TitleClassSelected = "ListTitleSelected";
const DotsClass = "ListDots";
const DescriptionClass = "ListDescription";
const SelectedTagsClass = "ListTagsSelected";
const TagsClass = "ListTags";
const ListCategory = "ListCategory";

function App() {
    const [selectedProject, setProject] = useState(null);
    const [selectedTag, setTag] = useState(null);
    const [showList, setShowList] = useState(true);

    let selectedProjectWhenNoTag = selectedTag != null ? null : selectedProject;
    window.$(document).mousemove(function (e) {
        let offset = -225;
        // let offset = e.pageY > 700 ? -225 : 5;
        window.$("#follow").css({
            left: e.pageX + 50,
            top: e.pageY + offset
            // top:0// 300//e.pageY+ offset
        });
    });
    let selectedImage = function () {
        return selectedProjectWhenNoTag ? require('./gifs/' + data.default.filter(p => selectedProjectWhenNoTag === p)[0].gif) : ""
    };

    let allTags = data.default
        .filter(p => p.tags)
        .map(p => p.tags.split(" "))
        .flat();
    const uniqueTags = [...new Set(allTags)].sort();
    let colors = Object.assign({}, ...uniqueTags.map((x, i) => ({[x]: `hsl(${150 + i * 8}, ${40}%, ${60}%)`})));

    return (
        showList ?
            <div className="Site">
                <button className="ListSwitch" onClick={() => setShowList(false)}>Grid</button>
                <div className="ProjectList">
                    <div className="PageName">Dominik Ganghofer</div>
                    <div className="PageTitle">Projects</div>
                    <BuildSubList
                        key="CAD"
                        list={data.default}
                        callback={setTag}
                        projectCallback={setProject}
                        selectedTag={selectedTag}
                        selectedProject={selectedProjectWhenNoTag}
                        colors={colors}
                        category="CAD"/>
                    <BuildSubList
                        key="Configurator"
                        list={data.default}
                        callback={setTag}
                        projectCallback={setProject}
                        selectedTag={selectedTag}
                        selectedProject={selectedProjectWhenNoTag}
                        colors={colors}
                        category="Configurator"/>
                    <BuildSubList
                        key="AR & VR"
                        list={data.default}
                        callback={setTag}
                        projectCallback={setProject}
                        selectedTag={selectedTag}
                        selectedProject={selectedProjectWhenNoTag}
                        colors={colors}
                        category="AR & VR"/>
                    <BuildSubList
                        key="Other"
                        list={data.default}
                        callback={setTag}
                        projectCallback={setProject}
                        selectedTag={selectedTag}
                        selectedProject={selectedProjectWhenNoTag}
                        colors={colors}
                        category="Other"/>
                </div>

                {selectedProjectWhenNoTag ?
                    <div id="follow">
                        <img className="ListImage" src={selectedImage()} key={selectedImage()}/>
                        {/*<div className="ImageLabel">{selectedProject.label}</div>*/}
                    </div> : <div/>}


            </div>
            :
            <div className="Site">
                <button className="ListSwitch" onClick={() => setShowList(true)}>List</button>

                <BuildProjectGrid
                    list={data.default}
                    callback={setTag}
                    projectCallback={setProject}
                    selectedTag={selectedTag}
                    selectedProject={selectedProjectWhenNoTag}
                    colors={colors}
                />
            </div>
    );
}

function BuildProjectGrid(props) {
    let list = props.list.filter(p => p.gif && p.category != "x");
    return <div className="ProjectGrid">
        <div className="PageName">Dominik Ganghofer</div>
        <div className="PageTitle">Projects</div>
        {list.map((p, index) => (
            <div className={p == props.selectedProject ? "GridField" : "GridField"}
                 onMouseOver={() => props.projectCallback(p)}
                 onMouseOut={() => props.projectCallback(null)}>
                <img className="GridImage"
                     src={require('./gifs/' + p.gif)}
                     key={p.gif}
                     placeholder= './gifs/tiny-image.jpg'/>
                <div className="GridText">
                    <p className={p == props.selectedProject ? "GridTitle" : "GridTitle"}
                    >{p.label}</p>
                    <span className={DescriptionClass}>{p.what}</span>
                    <p>{p.tags
                        ? p.tags
                            .split(" ")
                            .sort()
                            .map((tag, index) => (
                                <span className={props.selectedTag === tag ? SelectedTagsClass : TagsClass}
                                      onMouseOver={() => props.callback(tag)}
                                      onMouseOut={() => props.callback(null)}
                                      style={{color: props.colors[tag]}}
                                >{tag}</span>))
                        : <span/>}
                    </p>
                </div>

            </div>
        ))}</div>
}

function BuildSubList(props) {
    let list = props.list.filter(p => p.category === props.category);
    return <div>
        <div className={ListCategory}>{props.category}</div>
        {list.map((p, index) => (
            <div key={p.label + "main"}
                 className={p == props.selectedProject ? "ListRowSelected" : "ListRow"}
                 onMouseOver={() => props.projectCallback(p)}
                 onMouseOut={() => props.projectCallback(null)}>
                <span
                    key={p.label}
                    className={p == props.selectedProject ? TitleClassSelected : TitleClass}
                >{p.label}</span>
                <span key={p.label + "arrow"}
                      className={DotsClass}
                >&#8594;
                </span>
                <span key={p.label + "what"}
                      className={DescriptionClass}
                >{p.what}
                </span>
                <span key={p.label + "slash"}
                      className={DotsClass}
                >|
                </span>
                {p.tags
                    .split(" ")
                    .sort()
                    .map((tag, index) => (
                        <span key={index + tag} className={props.selectedTag === tag ? SelectedTagsClass : TagsClass}
                              onMouseOver={() => props.callback(tag)}
                              onMouseOut={() => props.callback(null)}
                              style={{color: props.colors[tag]}}
                        >{tag} </span>))
                }
            </div>
        ))}</div>
}


function ProjectItem(props) {

    const handleMouseIn = () => {
        props.callback(props.projectData.label);
    };
    const handleMouseOut = () => {
        props.callback(null);
    };
    return (
        <div className="Item" onMouseOver={handleMouseIn} onMouseOut={handleMouseOut}>
            <div className="ProjectTitle">
                {props.projectData.label}
            </div>
            {/*<img src={require('./gifs/' + props.projectData.gif)} alt="loading..."/>*/}
            <ProgressiveImage src={require('./gifs/' + props.projectData.gif)} placeholder='./gifs/tiny-image.jpg'>
                {(src) => <img src={src} alt=""/>}
            </ProgressiveImage>
            <div className="ProjectWhat">
                {props.projectData.what}
            </div>
        </div>
    );
}

function TagButton(props) {
    const handleMouseIn = () => {
        props.callback(props.label);
    };
    const handleMouseOut = () => {
        props.callback(null);
    };
    return <div class={props.selected ? "HoveredButton" : "NormalButton"} onMouseOver={handleMouseIn}
                onMouseOut={handleMouseOut}>{props.label}</div>;


}

export default App;



